.eventItem {
  width: 100%;
  border-bottom: 1px solid rgb(164, 164, 164);
}

.eventInfo {
  color: var(--color2);
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
}

.eventInfo:hover {
  color: #333232;
}
